import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTickerNew';
import './ClientLogos.scss';
import Image from '@ui/Image';

const { block, element } = bem('client-logos');

const logos = ['teorema', 'sitronics', 'wa', 'zenit', 'gpn', 'lidval', 'tiptop', 'case', 'duplex', 'glandia', 'elmagroup'];

const logoComponents = logos.map((name) => {
  return (
    <Image
      src={`/images/client-logos/${name}.png`}
      className="mx-5 h-6 sm:mx-3 md:mx-2.5 lg:h-8"
      key={name}
      alt={`${name}_logo`}
    />
  );
});

const ClientLogos = () => {
  return (
    <div {...block()}>
      <ItemsTicker
        items={logoComponents}
      />
    </div>
  );
};

export default ClientLogos;
