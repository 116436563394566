import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';
import './BlurredQR.scss';

const { block, element } = bem('blurred-qr');

const BlurredQR = ({ className }) => {
  return (
    <div {...block({}, className)}>
      <StaticIcon name="hero-qr" {...element('qr')} />
    </div>
  );
};

export default BlurredQR;
