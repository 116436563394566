import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';
import { Mockup } from '@ui/Mockup';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import Typist from 'react-text-typist';
import { TryButton } from '@ui/Price/PriceBlock';
import { BlurredQR } from '../BlurredQR';
import CheckText from '../CheckText';

import './HeroSection.scss';
import { CircleBlur } from '../CircleBlur';

const {
  block,
  element,
} = bem('hero-section');

const Benefits = ({
  benefits,
  t,
}) => {
  return (
    <div className={clsx(
      'xl:grid-cols sm:space-y-6 sm:py-7 md-lg:flex-col md-lg:space-y-7 md-lg:py-8 xl:flex xl:py-9',
    )}
    >
      {
        benefits.map((benefit, i) => (
          <div key={i} className="xl:col-4 sm:flex sm:flex-col sm:items-center md-lg:text-left">
            <StaticIcon folder="main-page" name={benefit.icon} />
            <Typography variant="header5" className="mt-3 lg-only:h-[60px]">
              {benefit.title}
            </Typography>
            <Typography color="secondary300" className="mt-2">
              {benefit.body}
            </Typography>
          </div>
        ))
      }
    </div>
  );
};

const WavesDivider = () => {
  return (
    <div {...element('waves-divider')} />
  );
};

const HeroSection = ({
  className,
  t,
  containerClass,
}) => {
  const checkTexts = t('hero_section.check_texts');

  return (
    <div data-anchor="" {...block({}, className)}>
      <div className={clsx('grid-layout sm:mt-7 md-lg:mt-12', containerClass)}>
        <div className="xl:flex">
          <div {...element('left', {}, 'flex flex-col xl:col-7')}>
            <Typography variant="header1" className="font-bold">
              {t('hero_section.header')}
            </Typography>
            <Typography
              className="mt-4-md mt-3-xs-only"
              variant="subhead1"
              color="secondary300"
            >
              {t('hero_section.subheader')}
            </Typography>
            <div {...element('buttons-group')}>
              <TryButton text={t('try_free')} quickStart id="try_free" />
              <RequestFeedback.Demo.Button className="sm:mt-2 md-up:ml-2" outline>
                {t('request_demo')}
              </RequestFeedback.Demo.Button>
            </div>
            <div {...element('check-texts', {}, 'xl:space-x-2 md-lg:space-y-2 sm:space-y-2')}>
              {
                React.Children.toArray(checkTexts.map((text) => (
                  <CheckText text={text} />
                )))
              }
            </div>
          </div>
          <Mockup filled {...element('hero-image')}>
            <Mockup.Screen>
              <video
                autoPlay
                loop
                muted
                playsInline
                poster="/ws-hero-first-frame.jpg"
              >
                <source src="/ws-hero.mp4#t=0.1" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </Mockup.Screen>
            <BlurredQR
              className={clsx(
                'z-40',
                '!absolute',
                'xl:-left-4 xl:bottom-[23px]',
                'md-lg:left-[-22px] md-lg:bottom-[30px]',
                'sm:left-[4px] sm:bottom-[3px]',
              )}
            />
            <CircleBlur className={clsx(
              'z-0',
              '!absolute',
              'xl:left-[-87px] xl:bottom-[-110px]',
              'md-lg:left-[-87px] md-lg:bottom-[-42px]',
              'sm:left-[-1px] sm:bottom-[-51px]',
            )}
            />
          </Mockup>
        </div>
        <WavesDivider />
        <Benefits benefits={t('benefits')} t={t} />
      </div>
    </div>
  );
};

HeroSection.defaultProps = {
  className: '',
};

HeroSection.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  containerClass: PropTypes.string,
};

export default HeroSection;
