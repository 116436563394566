import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import StaticIcon from '@ui/StaticIcon';
import MediaQuery from '@ui/MediaQuery';
import Typography from '@ui/Typography';
import clsx from 'clsx';

import './PilotProject.scss';
import { Fragment } from 'react';
import { TryButton } from '@ui/Price/PriceBlock';

const {
  block,
  element,
} = bem('pilot-project');

const DayCard = ({ day }) => {
  const {
    header,
    header_icon_color,
    body: {
      title,
      points,
    },
  } = day;
  return (
    <div {...element('pilot-day-card')}>
      <div className="md:w-[380px]">
        {
          header_icon_color ? (
            <StaticIcon
              {...element('pilot-day-card-icon')}
              name="tag"
              colored
              color={header_icon_color}
            />
          ) : null
        }
        <Typography
          font="annotation"
          weight="bold"
          variant={null}
          {...element('pilot-day-card-header', {}, 'text-[20px] leading-[30px]')}
        >
          {header}
        </Typography>
        <Typography {...element('pilot-day-card-body-title')} className="font-medium">
          {title}
        </Typography>
        <div {...element('pilot-day-card-points', {}, 'flex-column')}>
          {
            points.map((point, i) => (
              <div key={i} {...element('pilot-day-point', {}, 'row')}>
                <div className="flex flex-col items-center">
                  <StaticIcon folder="main-page" name="pilot-day-point" />
                  {
                    i !== points.length - 1 && (
                      <div className="mt-1 -mb-1 h-[calc(100%_-_24px)] min-h-[24px] w-[2px] bg-tertiary200" />
                    )
                  }
                </div>
                <Typography color="secondary200" className="ml-3">
                  {point}
                </Typography>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

// const PilotDayCardWithAnimation = ({ day, index, transition }) => {
// 	gsap.registerPlugin(ScrollTrigger);
//
// 	return (
// 		<Tween
// 			to={{
// 				x: transition,
// 				scrollTrigger: {
// 					trigger: `.trigger-${index}`,
// 					start: 'top top',
// 					end: 'bottom bottom',
// 					scrub: 1,
// 				},
// 			}}
// 		>
// 			<DayCard day={day} />
// 		</Tween>
// 	);
// };

const tr = (key) => t(`main_page.pilot_project.${key}`);

const PilotProject = () => {
  const days = tr('days');
  return (
    <>
      <MediaQuery at="sm">
        <div>
          <div className="text-left sm:px-3">
            <Typography variant="header5" className="text-2xl font-semibold">
              {tr('title')}
            </Typography>
            <Typography variant="subhead1" className="mt-3 text-secondary400">
              {tr('subtitle')}
            </Typography>
          </div>
          <div className="grid-layout mx-auto mt-5 sm:px-3">
            <TryButton text={tr('begin_now')} quickStart id="begin_now" className="w-full" />
          </div>
          <div className="mt-5 mb-8 flex justify-center">
            <StaticIcon
              folder="main-page"
              name="help-each-stage"
            />
          </div>
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className={clsx(
          'md-up:grid-layout relative md-up:mx-auto lg:flex lg:flex-col lg:py-15',
          'md:my-8',
        )}
        >
          <div className="lg:col-offset-start-1 lg:col-10 md:text-center lg:text-center">
            <MediaQuery at="md">
              <Typography variant="header5" className="text-2xl lg:font-semibold">
                {tr('title')}
              </Typography>
            </MediaQuery>
            <MediaQuery greaterThan="md">
              <Typography variant="header2" className="font-semibold">
                {tr('title')}
              </Typography>
            </MediaQuery>
            <Typography variant="subhead1" className="text-secondary400 md:mt-3 lg:mt-4">
              {tr('subtitle')}
            </Typography>
            <div className="relative">
              <TryButton text={tr('begin_now')} quickStart id="begin_now" className="md:mt-4 lg:mt-5" />
            </div>
          </div>
          <div className="mt-5 flex justify-center">
            <StaticIcon
              folder="main-page"
              name="help-each-stage"
            />
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

export default PilotProject;
