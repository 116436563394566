import { bem } from '@lib/bem';
import MediaQuery from '@ui/MediaQuery';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import { ClientLogos } from '@widgets/client-logos';
import StaticIcon from '@ui/StaticIcon';
import PersonalReview from '@ui/PersonalReview';
import Review from '@ui/Review';
import VideoReview, { VideoContext } from '@ui/VideoReview';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Feedback.scss';

import { useEffect, useState } from 'react';

const NUMBER_OF_ROWS = 3;
const CARD_PER_ROW = 3;

const {
  block,
} = bem('feedback');

const ShowMoreButton = ({ onButtonClick }) => {
  return (
    <button
      className={clsx(
        'group flex items-center justify-center',
        'rounded-2xl border-[3px] border-solid border-tertiary200',
        'bg-white',
      )}
      type="button"
      onClick={onButtonClick}
    >
      <div className="flex gap-1">
        <Typography>Читать больше отзывов</Typography>
        <StaticIcon name="arrow-right" className="rotate-90" />
      </div>
    </button>
  );
};

const Feedback = ({
  t,
  clients,
  people,
}) => {
  const [showButton, setShowButton] = useState(people.length > CARD_PER_ROW * NUMBER_OF_ROWS);
  const [rows, setRows] = useState(NUMBER_OF_ROWS);
  const [showData, setShowData] = useState(people.slice(0, rows * CARD_PER_ROW));

  const [currentVideo, setCurrentVideo] = useState('');

  useEffect(() => {
    setShowData(people.slice(0, rows * CARD_PER_ROW));

    if (showData.length === people.length) {
      setShowButton(false);
    }
  }, [rows, people, showData.length]);

  const showMoreHandle = () => {
    setRows(rows + 1);
  };

  const videoToggle = (id) => {
    setCurrentVideo(id);
  };

  return (
    <div id="feedback" className="scroll-mt-5 py-15 sm:scroll-mt-15 sm:py-7 md:scroll-mt-11 md:pt-8 md:pb-10">
      <VideoContext.Provider value={currentVideo}>
        <div className="mx-auto sm:mx-3">
          <Typography variant="header2" align="center">
            {t('feedback.title')}
          </Typography>
        </div>
        <div {...block(
          {},
          'md-up:grid-layout mx-auto mt-9 sm:mt-7 md:mt-8 px-8 pb-4.5 pt-8 sm:px-3 sm:mx-3 sm:pt-7 md:p-6 rounded-2xl border-[3px] border-tertiary200 bg-[url(../../../static/images/main-page/bg-feedback.png),linear-gradient(94.09deg,#FFFFFF_0%,#FCFDFF_22.12%,#FAFCFF_42.74%,#F9FBFF_57.51%,#F6F9FF_69.1%,#F4F7FF_84.38%,#F3F7FF_100%)]',
          'bg-no-repeat',
          '[background-position:right_0px_bottom_0px]',
          'sm:bg-[linear-gradient(94.09deg,#FFFFFF_0%,#FCFDFF_22.12%,#FAFCFF_42.74%,#F9FBFF_57.51%,#F6F9FF_69.1%,#F4F7FF_84.38%,#F3F7FF_100%)]',
        )}
        >
          <Slider
            settings={{
              infinite: true,
              fade: true,
              speed: 1000,
            }}
          >
            {clients.map((clientReview, i) => (
              <Review key={clientReview.logo} item={clientReview} />
            ))}
          </Slider>
        </div>
        <ClientLogos />
        <MediaQuery greaterThan="md">
          <div className="mx-auto grid max-w-[1152px] grid-cols-3 gap-3">
          
            {showData.map((person, i) => {
              if (showButton && i === showData.length - 1) {
                return <ShowMoreButton onButtonClick={showMoreHandle} />;
              }
              const card = person.type === 'text' 
                ? <PersonalReview person={person} key={person.name} /> 
                : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
              return card;
            })}
          
          </div>
        </MediaQuery>
        <div className="grid-layout mx-auto">
          <MediaQuery at="md">
            <Slider settings={{ slidesToShow: 1.8 }} gap={4}>
              {people.map((person) => {
                const card = person.type === 'text' 
                  ? <PersonalReview person={person} key={person.name} /> 
                  : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
                return card;
              })}
            </Slider>
          </MediaQuery>
          <MediaQuery at="sm">
            <Slider settings={{ slidesToShow: 1 }} gap={2}>
              {people.map((person) => {
                const card = person.type === 'text' 
                  ? <PersonalReview person={person} key={person.name} /> 
                  : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
                return card;
              })}
            </Slider>
          </MediaQuery>
        </div>
      </VideoContext.Provider>
    </div>
  );
};

Feedback.propTypes = {
  t: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
};

export default Feedback;
