import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';
import MediaQuery from '@ui/MediaQuery';

import Typography from '@ui/Typography';
import PropTypes from 'prop-types';

import './QRBanner.scss';

const {
  block,
  element,
} = bem('qr-banner');

const QRBanner = ({ t }) => {
  const blockName = 'qr_banner';
  return (
    <div {...block({}, 'lg:bg-purple100/[.14]')}>
      <div className="grid-layout">
        <div className="lg:col-10 lg:col-offset-start-1 p-0  ">
          <Typography variant="header2" align="center" className="mt-9-lg mt-8-md-only">
            {t('title', blockName)}
          </Typography>
          <Typography variant="subhead1" color="secondary300" align="center" className="mt-4-lg mt-3-md-only">
            {t('subtitle', blockName)}
          </Typography>
          <MediaQuery at="md">
            <div {...element('qr-cursor')}>
              <StaticIcon folder="main-page" name="qr-cursor" />
            </div>
          </MediaQuery>
          <MediaQuery greaterThan="md">
            <div
              className="my-5 mx-auto flex h-[348px] w-[348px] flex-col items-center justify-center rounded-full bg-gray500"
            >
              <StaticIcon folder="main-page" name="qr-cursor-lg" />
              <Typography variant="caption1" className="mt-2 text-center !text-white">
                {t('hover_text', blockName)}
              </Typography>
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
  );
};

QRBanner.propTypes = {
  t: PropTypes.func.isRequired,
};

export default QRBanner;
