import Marquee from 'react-fast-marquee';

const defaultProps = {
  speed: 75,
  gradient: false,
};
const ItemsTickerNew = ({ items = [], tickerProps }) => {
  const settings = { ...defaultProps, ...tickerProps };
  return (
    <Marquee {...settings}>
      {Object.values(items).map((f) => (f))}
    </Marquee>
  );
};

export default ItemsTickerNew;
