import { bem } from '@lib/bem';
import AccordionMenu from '@ui/AccordionMenu';
import StaticIcon from '@ui/StaticIcon';
import List from '@ui/List';
import MediaQuery from '@ui/MediaQuery';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import './RoleBenefits.scss';

const {
  block,
  element,
} = bem('role-benefits');

const RolesListItem = ({ item, selected }) => {
  return (
    <>
      <Typography
        {...element('list-item-title')}
        variant="header3"
        color={selected ? 'secondary400' : 'secondary100'}
      >
        {item.role_name}
      </Typography>
      {selected && (
        <StaticIcon
          {...element('list-item-icon')}
          name="arrow-right"
        />
      )}
    </>
  );
};

const BenefitsListItem = ({ item }) => {
  return (
    <div className="flex">
      <div className="h-2 w-2 py-0.5">
        <StaticIcon folder="main-page" name="check" />
      </div>
      <Typography {...element('benefits-list-item-text', {}, 'ml-[10px]')}>
        {item}
      </Typography>
    </div>
  );
};

const BenefitsList = ({ items, className }) => {
  return (
    <List className={clsx('lg:space-y-4 lg-down:space-y-[28px]', className)}>
      {
        items.map((item, i) => (
          <List.Item key={i}>
            <BenefitsListItem item={item} />
          </List.Item>
        ))
      }
    </List>
  );
};

const RoleBenefits = ({ t }) => {
  const blockName = 'role_benefits';
  const roles = t('switch_items', blockName);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const selectRoleBenefits = (index) => () => {
    setSelectedItemIndex(index);
  };

  const accordionMenuItems = useMemo(() => {
    return roles.map((item, i) => ({
      title: item.role_name,
      renderContent: () => <BenefitsList items={roles[i].items} />,
    }));
  }, [roles]);

  const Content = useCallback(({ className }) => {
    return (
      <div className={clsx('row lg:col-offset-start-1 lg:col-10', className)}>
        <div className="lg-down:w-full">
          <div className="lg:col-4">
            <Typography
              variant="caption2"
              className="sm:text-left lg:mb-5 lg-down:mb-4"
              transform="uppercase"
              color="teal500"
            >
              {t('title', blockName)}
            </Typography>
            <MediaQuery lessThan="lg">
              <AccordionMenu items={accordionMenuItems} />
            </MediaQuery>
            <MediaQuery greaterThan="md">
              <List className="space-y-3">
                {
                  roles.map((role, i) => (
                    <List.Item
                      key={i}
                      onClick={selectRoleBenefits(i)}
                      {...element('list-item', {}, 'flex cursor-pointer items-center py-[3px]')}
                    >
                      <RolesListItem
                        item={role}
                        selected={selectedItemIndex === i}
                      />
                    </List.Item>
                  ))
                }
              </List>
            </MediaQuery>
          </div>
        </div>
        <MediaQuery greaterThan="md">
          <div className="w-full">
            <div className={clsx(
              'rounded-[16px] border-[3px] border-solid border-tertiary200',
              'flex h-full bg-white py-6 px-[80px]',
            )}
            >
              <BenefitsList items={roles[selectedItemIndex].items} className="my-auto" />
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }, [accordionMenuItems, selectedItemIndex, roles, t]);

  return (
    <div {...block({}, 'row center lg:py-[120px] md:pt-8 md:pb-[67px] sm:py-7')}>
      <MediaQuery at="sm">
        <div className="grid-layout mx-auto">
          <Content />
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className="grid-layout">
          <Content />
        </div>
      </MediaQuery>
    </div>
  );
};

RoleBenefits.propTypes = {
  t: PropTypes.func.isRequired,
};

export default RoleBenefits;
