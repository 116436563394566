import { RequestFeedback } from '@features/request-feedback';
import Image from '@ui/Image';
import { Mockup } from '@ui/Mockup';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { TryButton } from '@ui/Price/PriceBlock';
import CheckText from '../CheckText';
import { CircleBlur } from '../CircleBlur';

const CTA = ({
  t,
  items,
}) => {
  return (
    <div className="grid-layout my-7 mx-auto sm:px-3">
      <div className="flex md-lg:text-center xl-down:flex-col">
        <div className="xl:col-7 xl:mt-[342px] xl:flex xl:flex-col">
          <Typography variant="header2">
            {t('cta.title')}
          </Typography>
          <div className="flex flex-col md-lg:flex-col-reverse md-lg:items-center md-lg:text-center">
            <div className="flex space-x-2 sm:hidden xl:my-5">
              {
                React.Children.toArray(items.map((item, i) => (
                  <CheckText text={item} />
                )))
              }
            </div>
            <div className="flex sm:mt-5 sm:flex-col sm:space-y-2 md-up:space-x-2 md-lg:my-5">
              <TryButton text={t('try_free')} quickStart id="try_free" />
              <RequestFeedback.Consultation.Button outline>
                {t('pilot_project.request_consultation')}
              </RequestFeedback.Consultation.Button>
            </div>
          </div>
        </div>
        <Mockup className="sm:mt-6 md:mx-auto md-lg:mt-[88px] xl:ml-auto xl:mt-[120px] xl-down:mx-auto">
          <CircleBlur
            className={clsx(
              '!absolute z-0',
              'lg:left-[-114px] lg:bottom-[112px]',
              'md:left-[21px] md:bottom-[173px]',
            )}
          />
          <Image
            alt=""
            src="/images/main-page/notifications1.webp"
            className={clsx(
              '!absolute z-50  h-[88px] w-[359px]',
              'md-up:left-[-38px] md-up:bottom-[359px]',
              'sm:left-[-20px] sm:bottom-[184px] sm:h-[66px]',
            )}
          />
          <Image
            alt=""
            src="/images/main-page/notifications2.webp"
            className={clsx(
              '!absolute z-50 w-[359px]',
              'lg:left-[13px] lg:bottom-[269px]',
              'md:left-[26px] md:bottom-[269px]',
              'sm:!hidden',
            )}
          />
          <Mockup.Screen>
            <Image alt={t('cta.image_alt')} src="/images/main-page/wave-service-request-notifications.png" />
          </Mockup.Screen>
        </Mockup>
      </div>
    </div>
  );
};

CTA.propTypes = {
  t: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default CTA;
