import Typography from '@ui/Typography';
import clsx from 'clsx';

export const BlockCaption = ({
  children, className, align = 'center', ...rest
}) => {
  return (
    <div
      className={clsx(
        align === 'center' && 'justify-center',
        'flex',
      )}
    >
      <Typography
        transform="uppercase"
        className={clsx('inline px-[12px] py-[6px] tracking-caption', className)}
        variant="caption2"
        as="div"
        {...rest}
      >
        {children}
      </Typography>
    </div>
  );
};
