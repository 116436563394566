import { bem } from '@lib/bem';
import StaticIcon from '@ui/StaticIcon';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Functional.scss';
import { BlockCaption } from '../BlockCaption';

const {
  block,
} = bem('functional');

const Wrapper = ({
  link, children, className, key,
}) => {
  if (link) {
    return <a href={link} target="_blank" className={className} key={key}>{children}</a>;
  }

  return <div className={className} key={key}>{children}</div>;
};
const FunctionalCard = ({
  card: {
    body,
    icon,
    title,
    link,
  },
}) => {
  return (
    <Wrapper
      link={link}
      key={icon}
      className={clsx(
        'text-left',
        'rounded-[16px] border-[2px] border-tertiary200 p-3',
        'lg:border-[3px] xl:p-4',
        'md:rounded-[12px]',
        link && 'hover:border-[3px] hover:border-solid hover:border-tertiary200 hover:bg-white hover:shadow-[6px_6px_40px_#ECF2FF]',
      )}
    >
      <div>
        {icon && <StaticIcon folder="main-page" name={icon} />}
      </div>
      <Typography className="mt-3" weight="semibold">
        {title}
      </Typography>
      <Typography color="secondary200" variant="body2" className="mt-1">
        {body}
      </Typography>
    </Wrapper>
  );
};

const Functional = ({
  t,
}) => {
  const cardItems = t('functional.cards');

  return (
    <div id="features" {...block({}, 'md:pt-8 lg:pt-12 sm:pt-7 lg:pb-9 md:pb-8 sm:pb-7 scroll-mt-18 lg:scroll-mt-14')}>
      <div className="grid-layout mx-auto lg:flex lg:flex-col">
        <BlockCaption color="cyan500" className="bg-[rgba(0,173,211,0.08)]">
          {t('functional.caption')}
        </BlockCaption>
        <Typography
          variant="header2"
          align="center"
          className="mt-4 sm:mt-3"
        >
          {t('functional.title')}
        </Typography>
        <div className="lg:ml-1-col lg:mr-1-col sm:flex sm:space-x-4">
          <div className={clsx(
            'grid sm:mt-8 md-up:gap-3 lg:mt-9 lg:grid-cols-3',
            'md:mt-7 md:grid-cols-2',
            'sm:space-y-3',
          )}
          >
            {
              cardItems.map((card, i) => (
                <FunctionalCard
                  key={i}
                  card={card}
                />
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

Functional.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Functional;
