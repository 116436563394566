import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import MediaQuery from '@ui/MediaQuery';
import { TryButton } from '@ui/Price/PriceBlock';

import Typography from '@ui/Typography';
import PropTypes from 'prop-types';

import './DiscussProblemBlock.scss';

const {
  block,
  element,
} = bem('discuss-problem-block');

const BlockContent = ({
  className,
  t,
}) => {
  return (
    <div {...element('card', {}, className, 'shadow-[6px_6px_40px_#ECF2FF] border-[3px] border-solid border-white rounded-2xl')}>
      <div className="md:col-8 md:col-offset-start-1 sm:mx-auto sm:px-3 sm:py-7 md:py-8 lg:px-14 lg:py-7">
        <div className="row middle-lg flex-column-less-than-lg sm:text-left md:text-center">
          <div className="flex-column">
            <Typography variant="header5">
              {t('discuss_problem.header')}
            </Typography>
            <Typography variant="subhead1" {...element('discuss-problem-subhead', {}, 'mt-2-lg mt-3-less-than-lg max-w-[640px]')}>
              {t('discuss_problem.subheader')}
            </Typography>
          </div>
          <TryButton 
            text={t('discuss_problem.submit_button_text')} 
            quickStart 
            id="submit_button_text"
            className="ml-auto-lg mt-4-less-than-lg z-10 md:mx-auto md:self-start"
          />
        </div>
      </div>
    </div>
  );
};

const DiscussProblemBlock = ({ t }) => {
  return (
    <div {...block({}, '')}>
      <MediaQuery at="sm">
        <div className="mx-3">
          <BlockContent t={t} />
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="sm">
        <div className="mx-auto max-w-[1312px] xl-down:mx-4.5">
          <BlockContent t={t} />
        </div>
      </MediaQuery>
    </div>
  );
};

DiscussProblemBlock.propTypes = {
  t: PropTypes.func.isRequired,
};

export default DiscussProblemBlock;
