import React from 'react';
import { bem } from '@lib/bem';
import clsx from 'clsx';
import { QuoteSvg } from '@ui/QuoteSvg';
import Typography from '@ui/Typography';
import Image from '@ui/Image';

import ArrowRight from '../../../../static/images/arrow-right.svg';

const {
  element,
} = bem('feedback');

const imageBorderColor = {
  teal: 'shadow-[0_0_0_2px_rgba(135,255,217,1)]',
  purple: 'shadow-[0_0_0_2px_rgba(217,135,255,1)]',
  orange: 'shadow-[0_0_0_2px_rgba(255,158,135,1)]',
  cyan: 'shadow-[0_0_0_2px_rgba(135,233,255,1)]',
  pink: 'shadow-[0_0_0_2px_rgba(255,135,233,1)]',
};

const blendColor = {
  teal: 'before:bg-teal500',
  purple: 'before:bg-purple500',
  orange: 'before:bg-orange500',
  cyan: 'before:bg-cyan500',
  pink: 'before:bg-pink500',
};

const PersonalReview = ({ person }) => {
  const {
    name, position, text, photo, photo_alt, link, color,
  } = person;

  return (
    <div {...element(
      'personal-card', 
      {},
      'flex flex-col pl-4 pb-4 pt-[27px] pr-[27px] sm:h-[488px] md:h-[454px]',
      'rounded-2xl border-[3px] border-solid border-tertiary200',
      'bg-white',
      'relative',
      'before:absolute before:-top-6 before:-left-6 before:h-[156px] before:w-[156px] before:rounded-full before:opacity-80 before:mix-blend-lighten before:blur-[32px] before:content-[""]',
      blendColor[color],
    )} 
    >
      <QuoteSvg fill={color} className="absolute left-4 top-[132px]" />
      <div className="flex items-center gap-[19px]">
        <div className="sm:flex sm:flex-col sm:items-start">
          <Typography transform="uppercase" variant="body2" className="text-start font-semibold">{name}</Typography>
          <Typography transform="uppercase" variant="body2" color="secondary300" className="text-start">{position}</Typography>
        </div>
        <Image src={`/images/avatars/${photo}`} alt={photo_alt} className={clsx('h-[94px] w-[94px] rounded-full border-[5px] border-transparent', imageBorderColor[color])} loading="eager" />
      </div>
      <Typography className="mt-[27px] flex-[1_0_auto] text-2lg !text-secondary100 sm:text-left">
        {text}
      </Typography>
      <a href={link} target="_blank" rel="noopener noreferrer nofollow">
        <div className="group mt-2.5 flex items-center gap-0.5">
          <Typography className="italic !text-secondary100 group-hover:!text-primary400">О кейсе</Typography>
          <ArrowRight className="w-[22px] stroke-secondary100 transition group-hover:translate-x-[4px] group-hover:stroke-primary400" />
        </div>
      </a>
    </div>
  );
};

export default PersonalReview;
