import { bem } from '@lib/bem';
import Typography from '@ui/Typography';
import Image from '@ui/Image';
import StaticIcon from '@ui/StaticIcon';

const {
  element,
} = bem('feedback');

const Icons = {
  teorema: <StaticIcon folder="clients" name="teorema" />,
  wa: <StaticIcon folder="clients" name="wavepoint" />,
  gpn: <StaticIcon folder="clients" name="gpn" />,
  duplex: <StaticIcon folder="clients" name="duplex" />,
  case: <StaticIcon folder="clients" name="case" />,
  nda: <StaticIcon folder="clients" name="nda" />,
};

const Review = ({ item }) => {
  const IconComponent = Icons[item.logo];

  return (
    <div className="flex items-center justify-between sm:flex-col-reverse md:flex-col ">
      <div className="max-w-[850px]">
        <Typography color="secondary100" className="text-xl sm:mt-4 sm:text-lg">
          {item.text}
        </Typography>
        <div className="!mt-auto pt-4 md:pt-5">
          <div className="flex items-center gap-[6px] sm:justify-center">
            <Typography variant="body2" weight="semibold" transform="uppercase">
              {item.name}
            </Typography>
            {!item.logo && <Image src="/images/main-page/nda.png" className="h-[14px]" />}
          </div>
          <div className="mt-[6px] flex items-center sm:mt-1 sm:flex-col sm:gap-1">
            <Typography variant="body2" color="secondary100" transform="uppercase">
              {item.type}
            </Typography>
            {item.area_size && (
              <>
                <div {...element('review-divider', {}, 'bg-primary400 mt-[1px] sm:hidden')} />
                <Typography variant="body2" color="secondary100" transform="uppercase">
                  {item.area_size}
                </Typography>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex h-[61px] md:mt-4.5 md:self-end">
        {IconComponent}
      </div>
    </div>
  );
};

export default Review;
